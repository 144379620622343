import React from 'react'
import classNames from 'classnames'

import logEvent from 'src/js/analytics'

import { IOS_APP_URL, ANDROID_APP_URL } from 'src/constants'
import { DOWNLOAD_IOS, DOWNLOAD_ANDROID } from 'src/js/analytics/trackers'

const MobileDownloadButtons = ({ altBackgroundGradient }) => {
  return (
    <div className="x-mobile-download-buttons">
      <a
        href={IOS_APP_URL}
        className={classNames('x__button x-button-store x__button--top-margin ga-click-event', {
          'x__button--alt-gradient': altBackgroundGradient,
        })}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => {
          logEvent(DOWNLOAD_IOS())
        }}
      >
        <span className="x__button-align">
          <span className="x__button-align-text">
            <svg version="1.1" viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg">
              <title>Download on the App Store</title>
              <path
                d="m14.797 11.58c0.0020632 1.9194 1.1515 3.6515 2.9192 4.3992-0.33998 1.1044-0.85244 2.1481-1.5184 3.0925-0.89438 1.3379-1.8321 2.645-3.3205 2.6691-1.4462 0.03338-1.9322-0.8511-3.591-0.8511-1.6743 0-2.1923 0.82695-3.5794 0.88448-1.4174 0.0525-2.5007-1.428-3.4276-2.7533-1.8526-2.7066-3.2952-7.6277-1.3612-10.976 0.90818-1.632 2.6062-2.6676 4.4729-2.7279 1.4187-0.02919 2.7362 0.95814 3.6085 0.95814 0.85571 0 2.4837-1.1811 4.1629-1.0048 1.5961 0.049938 3.0752 0.84957 3.9912 2.1577-1.4453 0.88857-2.3347 2.4556-2.3566 4.1521zm-2.7316-8.0898c-0.78733 0.97926-1.9803 1.5433-3.2368 1.5304-0.079905-1.2278 0.33154-2.4371 1.1437-3.3614 0.82249-0.93683 1.9677-1.5294 3.2076-1.6596 0.096815 1.2642-0.30299 2.5164-1.1145 3.4906z"
                fill="#fff"
                fillRule="nonzero"
              />
            </svg>
            <span className="x-button-store__group">
              <span className="x-button-store__style1">Download on the</span>
              App Store
            </span>
          </span>
        </span>
      </a>
      <a
        className={classNames('x__button x-button-store x__button--top-margin ga-click-event', {
          'x__button--alt-gradient': altBackgroundGradient,
        })}
        href={ANDROID_APP_URL}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => {
          logEvent(DOWNLOAD_ANDROID())
        }}
      >
        <span className="x__button-align">
          <span className="x__button-align-text">
            <svg version="1.1" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg">
              <title>Download on Google Play</title>
              <g fill="none" fillRule="evenodd">
                <g fill="#fff" fillRule="nonzero">
                  <path
                    d="m0.67287 0.40765c-0.31876 0.39406-0.47603 0.89444-0.44 1.4v22.12c-0.034026 0.51038 0.13489 1.0135 0.47 1.4l0.07 0.08 12.36-12.39v-0.3l-12.39-12.38-0.07 0.07z"
                    opacity=".5"
                  />
                  <path
                    d="m17.233 17.148l-4.1-4.13v-0.3l4.1-4.13 0.09 0.06 4.91 2.78c1.4 0.79 1.4 2.09 0 2.89l-4.89 2.78-0.11 0.05z"
                    opacity=".9"
                  />
                  <path
                    d="m17.353 17.098l-4.22-4.23-12.46 12.46c0.58303 0.51897 1.4532 0.5484 2.07 0.07l14.61-8.3"
                    opacity=".3"
                  />
                  <path
                    d="m17.353 8.6476l-14.61-8.3c-0.61304-0.48528-1.4861-0.45997-2.07 0.06l12.46 12.46 4.22-4.22z"
                    opacity=".7"
                  />
                </g>
              </g>
            </svg>

            <span className="x-button-store__group">
              <span className="x-button-store__style1">Download on </span>
              Google Play
            </span>
          </span>
        </span>
      </a>
    </div>
  )
}

export default MobileDownloadButtons
