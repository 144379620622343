import classNames from 'classnames'
import { Link } from 'gatsby'
import find from 'lodash/find'
import React, { useEffect, useState } from 'react'
import Rellax from 'rellax'

import PlatformDropdown, { platforms } from 'components/button/Download/PlatformDropdown'
import PageHead from 'components/head/PageHead'
import Heading from 'components/heading/Heading'
import { Header, Footer } from 'components/layout'
import LogosCarousel from 'components/logos-carousel'
import MobileDownloadButtons from 'components/mobile-download-buttons'
import ResponsiveCarousel from 'components/responsive-carousel'
import AnimatedNumber from 'js/utils/animate-numbers'
import fetchAssetsCount from 'js/utils/fetchAssetsCount'
import fetchAssetsAPY from 'js/utils/fetch-assets-apy'
import { useAssets } from 'js/utils/hooks/useAssetsBase'
import startsWithVowel from 'js/utils/startsWithVowel'
import {
  ASSETS_PAGE_PATH,
  SITE_URL,
  SUPPORT_URL,
  ASSETPAGE_DESKTOP_DOWNLOAD_LINK,
  ASSETPAGE_MOBILE_DOWNLOAD_LINK,
  ASSETPAGE_WEB3_DOWNLOAD_LINK,
} from 'src/constants'

// Styles & Images
import 'static/asset-page-1/scss/styles.scss'

const linkLabels = {
  whitepaper: 'Whitepaper',
  chat: 'Official chat',
  twitter: 'Twitter',
  reddit: 'Reddit',
  website: 'Website',
}

const LinkItem = (props) => (
  <a
    href={props.url}
    target="_blank"
    rel="noreferrer noopener"
    className={`x-asset-page-1-about__links-${props.type}`}
  >
    {linkLabels[props.type]}
  </a>
)

const AssetPage1 = ({ pageContext, location }) => {
  const data = pageContext.data
  const [assetsCount, setAssetsCount] = useState(0)
  const [assetsAPY, setAssetsAPY] = useState({})
  const assetsData = useAssets()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedAssetsCount = await fetchAssetsCount({ assetsData })
        setAssetsCount(fetchedAssetsCount)

        const assetsAPY = await fetchAssetsAPY()
        setAssetsAPY(assetsAPY)
      } catch (error) {
        console.error('Failed to fetch data:', error)
        // Handle the error appropriately here...
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    window.addEventListener('message', (e) => {
      const message = e.data
      switch (message.type) {
        case 'UPDATE_GRAPH_HEIGHT': {
          const iframe = document.getElementById('graph-iframe')
          const updatedHeight = iframe.contentWindow.document.body.scrollHeight
          iframe.height = `${updatedHeight + 2}px`
          break
        }
      }
    })

    // scroll on click
    document.getElementById('x-header-scroll').addEventListener('click', function (e) {
      e.preventDefault()
      const headerscrollto = document.getElementById('x-asset-page-1-integrated')
      window.scrollTo({ top: headerscrollto.offsetTop - 80, behavior: 'smooth' })
    })

    // Load the header background image and only after that show by fading in
    const bgimage = new Image()
    const imgData = data.img
    if (window.innerWidth >= 768) {
      bgimage.src = imgData.bgL
    } else if (window.innerWidth < 768 && window.innerWidth > 320) {
      bgimage.src = imgData.bgM
    } else {
      bgimage.src = imgData.bgS
    }

    bgimage.addEventListener('load', function () {
      const xHeaderBg = document.getElementById('xHeaderBg')
      xHeaderBg.style.backgroundImage = `url(${data.src})`
      xHeaderBg.classList.add('x-header__bg--appear')
    })

    // Setup parallax scrolling
    Rellax('.rellax', {
      center: true,
    })

    // Normalize footer carousel's height
    function carouselNormalization() {
      const items = document.querySelectorAll('#testimonials .carousel-item') // grab all slides
      let heights = [] // create empty array to store height values
      let tallest // create variable to make note of the tallest slide

      function normalizeHeights() {
        heights = Array.from(items).map((item) => item.offsetHeight) // add heights to array
        tallest = Math.max(...heights) // cache largest value
        items.forEach((item) => {
          item.style.minHeight = `${tallest}px` // set min-height
        })
      }

      if (items.length) {
        normalizeHeights()
        window.addEventListener('resize', resetAndNormalize)
        window.addEventListener('orientationchange', resetAndNormalize)
      }

      function resetAndNormalize() {
        // reset vars
        tallest = 0
        heights = []
        items.forEach((item) => {
          item.style.minHeight = '0' // reset min-height
        })
        normalizeHeights() // run it again
      }
    }
    carouselNormalization()

    // style links
    document.querySelectorAll('.x-style-child-links a').forEach((node) => {
      node.style.color = data.colors.start
    })
  }, [assetsCount])
  // Find the asset data, the ticker will be the data.symbol
  const assetData = find(assetsData, (asset) => asset.properTicker === data.symbol)
  if (assetData === undefined) {
    console.log('Asset not found', data)
  }
  const page = data.symbol.toLowerCase() || 'status'

  // common
  /*
    const responsiveLineBreak = '<span class="x-break-lg" role="presentation"></span>'
    const walletActonsCopy = data.stake
      ? `Send, Receive, Stake, and Swap`
      : `Send, Receive, and Swap`
     */
  const linkKbSend = `${SUPPORT_URL}/article/34-how-do-i-send-a-blockchain-asset-bitcoin-ethereum-etc`
  const linkKbReceive = `${SUPPORT_URL}/article/33-how-do-i-receive-a-blockchain-asset-bitcoin-ethereum-etc`
  const linkKbExchange = `${SUPPORT_URL}/article/787-how-do-i-exchange-assets-in-exodus`
  const linkKbDesktopMobileSync = `${SUPPORT_URL}/article/1481-how-do-i-sync-my-exodus-wallet-to-a-different-device`
  const linkTrezor = `${SITE_URL}/trezor-wallet/`

  // body
  /*
    const mainTitle = data.mainTitle || data.h1 || `Exodus ${data.name} Wallet`
    const headerText1 =
      data.headerText1 ||
      `${walletActonsCopy} ${responsiveLineBreak} With Exodus' Free and Secure ${data.symbol} Wallet`
    const sendReceiveHeader = data.sendReceiveHeader || `Send and Receive ${data.name}`
     */
  const sendReceiveText =
    data.sendReceiveText ||
    `Send and receive ${data.symbol} easily with ${startsWithVowel(data.name) ? 'an' : 'a'} ${
      data.name
    } address or scannable QR code.<br />Learn how to <a href="${linkKbSend}">send</a> and <a href="${linkKbReceive}">receive</a> in Exodus.`
  /*
    const exchangeHeader =
      data.exchangeHeader || `Buy and sell ${responsiveLineBreak} ${data.name} instantly`
     */
  const exchangeText =
    data.exchangeText ||
    `Easily exchange ${
      data.name
    } for your favorite cryptos like ${'Ethereum, Monero, Litecoin,'.replace(
      data.name,
      'Bitcoin'
    )} and more right from your wallet, in seconds. No sign up required.<br />Learn how to <a href="${linkKbExchange}">swap</a> in Exodus.`
  /*
    const portfolioHeader =
      data.portfolioHeader || `View your portfolio holdings ${responsiveLineBreak} at a glance`
     */
  const portfolioText =
    data.portfolioText ||
    'Keep a pulse on your portfolio performance with real-time market data and historical charts.'
  /*
    const mobileHeader = data.mobileHeader || `Your ${data.name} Anywhere`
     */
  const mobileText =
    data.mobileText ||
    `Secure, manage, and swap your ${data.symbol} with the mobile security of face or fingerprint scanning.<br /><a href="${linkKbDesktopMobileSync}">Sync between the <strong>${data.name} desktop wallet</strong> and mobile wallet</a> to use your ${data.symbol} on multiple devices.`
  /*
    const trezorHeader = data.trezorHeader || 'Trezor Hardware Wallet Integration'
     */
  const trezorText =
    data.trezorText ||
    `<a href="${linkTrezor}">Combine the security of Trezor with the simplicity of Exodus</a> for the ultimate ${data.name} wallet experience.`
  /*
    const downloadPrompt =
      data.downloadPrompt ||
      `Get Exodus for ${
        assetData.trezor ? 'Desktop, Mobile, and Trezor' : 'Desktop and Mobile'
      } ${responsiveLineBreak} to ${walletActonsCopy} ${data.name}.`
     */
  const privateHeader = data.privateHeader || 'Private and Secure'
  const privateText =
    data.privateText ||
    'Exodus encrypts private keys and transaction data. On your device and for your eyes only. Your data remains private – no account setup or verification required.'
  const userFriendlyHeader = data.userFriendlyHeader || 'User-friendly'
  const userFriendlyText =
    data.userFriendlyText ||
    "We've prepared extensive detailed guides and video tutorials for you. Whether you're a new or advanced user, our 24/7 Support Team is here to help."
  const exchangePromoHeader = data.exchangePromoHeader || `Exchange ${data.name}`
  const exchangePromoText =
    data.exchangePromoText ||
    `Instantly and securely buy and sell ${data.name} with other crypto assets from the comfort of your wallet.<br />No sign up required.`

  const DOWNLOAD_LINKS_ENABLED =
    ASSETPAGE_DESKTOP_DOWNLOAD_LINK.includes(page) ||
    ASSETPAGE_MOBILE_DOWNLOAD_LINK.includes(page) ||
    ASSETPAGE_WEB3_DOWNLOAD_LINK.includes(page)

  const downloadDropdownProps = {
    colors: [data.colors.start, data.colors.stop],
    trackerPage: `${data.name}Page`,
    location,
    extensionLinkProps: {
      utmSource: data.path,
      utmCampaign: 'seo-landing',
    },
    androidLinkProps: {
      utmSource: 'exodus-website',
      utmCampaign: data.path,
      utmContent: data.path,
    },
  }

  return (
    <div className="x-page-asset-1">
      <Header />

      <main className="x">
        {/* Main Section */}
        <header className="x-header">
          <div className="x-header__darken" id="x-header-darken" role="presentation" />
          <div className="x-header__bg-container rellax" data-rellax-speed="-4" role="presentation">
            <div className="x-header__bg" id="xHeaderBg" />
          </div>

          <div className="x-header__content x__width">
            <div className="x-header__txt-container">
              <img src={`/img/logos/${data.symbol}.svg`} alt={data.name} className="x-asset-logo" />
              <span className="x-page-asset-1__apy-container">
                {data.staking && assetsAPY[data.symbol] && (
                  <span className="x-page-asset-1__apy">{assetsAPY[data.symbol]}% APY</span>
                )}
              </span>
              <Heading page={page} src="Assets" />
              <Heading
                type="h2"
                page={page}
                src="Assets"
                section="main"
                className="x-header__subheading"
              />

              {data.headerText2 && (
                <p className="x-header__subheading x-header__subheading--extra">
                  {data.headerText2}
                </p>
              )}
            </div>
          </div>

          <div
            className={classNames('x-header__buttons', {
              'x-header__buttons--v2': DOWNLOAD_LINKS_ENABLED,
            })}
          >
            {DOWNLOAD_LINKS_ENABLED ? (
              <>
                {ASSETPAGE_DESKTOP_DOWNLOAD_LINK.includes(page) && (
                  <PlatformDropdown platform={platforms.desktop} {...downloadDropdownProps} />
                )}
                {ASSETPAGE_MOBILE_DOWNLOAD_LINK.includes(page) && (
                  <PlatformDropdown platform={platforms.mobile} {...downloadDropdownProps} />
                )}
                {ASSETPAGE_WEB3_DOWNLOAD_LINK.includes(page) && (
                  <PlatformDropdown platform={platforms.web3} {...downloadDropdownProps} />
                )}
              </>
            ) : (
              <Link
                to={`/download/${data.downloadLinkUTM ? data.downloadLinkUTM : ''}`}
                className="x__button"
                style={
                  !data.defaultCTAColor
                    ? {
                        backgroundImage: `linear-gradient(-62deg, ${data.colors.stop} 4%, ${data.colors.start} 100%)`,
                      }
                    : {}
                }
              >
                <span className="x__button-align">
                  <span className="x__button-align-text">
                    Download Exodus
                    <span className="x-header__buttons-hide-sm">
                      {` `} {data.name} Wallet
                    </span>
                  </span>
                </span>
              </Link>
            )}
          </div>

          <p className="x-header__download-count">4 Million+ Downloads</p>

          <div className="x-header-scroll-container" id="x-header-scroll">
            <div className="x-header-scroll">
              <span className="x-header-scroll__ic">
                <svg version="1.1" viewBox="0 0 44 50" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="x-header__scroll-a" x1="100%" x2="0%" y1="100%">
                      <stop stopColor={data.colors.start} offset="0" />
                      <stop stopColor={data.colors.stop} offset="1" />
                    </linearGradient>
                  </defs>
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(-3)" strokeWidth="2">
                      <path
                        d="m27 2.3094c-1.2376-0.71453-2.7624-0.71453-4 0l-16.651 9.6132c-1.2376 0.71453-2 2.035-2 3.4641v19.226c0 1.4291 0.7624 2.7496 2 3.4641l16.651 9.6132c1.2376 0.71453 2.7624 0.71453 4 0l16.651-9.6132c1.2376-0.71453 2-2.035 2-3.4641v-19.226c0-1.4291-0.7624-2.7496-2-3.4641l-16.651-9.6132z"
                        stroke="url(#x-header__scroll-a)"
                      />
                      <polyline points="31 23 25 29 19 23" stroke="#fff" />
                    </g>
                  </g>
                </svg>
              </span>

              <span className="x-header-scroll__txt">Scroll down to explore</span>
            </div>
          </div>

          <div className="x-asset-page-1-integrated" id="x-asset-page-1-integrated">
            <div className="x-asset-page-1-integrated__badge1" role="presentation">
              <div className="x-asset-page-1-integrated__badge2">
                <img
                  src={`/img/logos/${data.symbol}.svg`}
                  alt={`${data.name} fully integrated to Exodus wallet`}
                />
              </div>
            </div>

            <svg
              version="1.1"
              className="x-asset-page-1-integrated__chrome"
              role="presentation"
              viewBox="0 0 1172 1012"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="x-asset-page-1-integrated-e"
                  x1="50.734%"
                  x2="49.266%"
                  y1="100%"
                >
                  <stop stopColor={data.colors.start} offset="0" />
                  <stop stopColor={data.colors.stop} offset="1" />
                </linearGradient>
                <linearGradient
                  id="x-asset-page-1-integrated-d"
                  x1="77.088%"
                  x2="22.912%"
                  y1="100%"
                >
                  <stop stopColor={data.colors.start} offset="0" />
                  <stop stopColor={data.colors.stop} offset="1" />
                </linearGradient>
                <linearGradient
                  id="x-asset-page-1-integrated-b"
                  x1="100%"
                  x2="0%"
                  y1="100%"
                  y2="100%"
                >
                  <stop stopColor={data.colors.start} offset="0" />
                  <stop stopColor={data.colors.stop} offset="1" />
                </linearGradient>
                <linearGradient
                  id="x-asset-page-1-integrated-c"
                  x1="29.072%"
                  x2="29.072%"
                  y1="100%"
                  y2="-3.7743%"
                >
                  <stop stopColor="#1F2033" offset="0" />
                  <stop stopColor="#1F2033" stopOpacity="0.3" offset="1" />
                </linearGradient>
                <path
                  id="x-asset-page-1-integrated-a"
                  d="m0 387v-104.84l40.991-1.1153 22.215-3.6651 25.2 3.6651 20.578-7.2583h31.041l6.2198 7.2583 19.797-7.2583 21.136 7.2583 10.134-10.993c5.3512-0.61984 9.3204-1.2024 11.908-1.7478 3.881-0.81804 27.454-15.547 28.554-15.398 0.73379 0.099312 6.9871-1.5038 18.76-4.8093h9.3285c5.3673 4.9639 8.5879 7.4762 9.6619 7.537 1.074 0.060785 8.2134-2.4515 21.418-7.537l37.665 25.689 8.3321-11.625h10.044l20.475-22.372 11.896 2.1421 27.129-9.7484 8.9926 3.4031c5.3961-3.285 8.6528-4.4194 9.77-3.4031 1.1172 1.0162 5.0415 2.7503 11.773 5.2023l37.804-31.368 28.533-1.5218 10.886-17.776 39.142-14.989 20.057-91.908 19.264 32.66h10.456l8.8335-30.09 12.178-8.9188 7.1615 12.477h12.353l18.294-50.266 19.37 5.9767 30.838 63.28 7.7277 27.817 12.068-11.926 8.2431 9.9731 11.294 1.9533 8.7571-33.208 17.822 21.282 11.562 1.7915 8.9238 29.606c6.7483-14.134 10.383-20.624 10.903-19.471s4.1406 3.7597 10.862 7.8195l10.993-25.988 17.817-5.4271c5.4564-18.659 8.5493-28.439 9.2789-29.34s4.1864-3.0179 10.371-6.3491l28.642 49.15 10.43-8.034 9.6733 25.988 22.089-14.557 15.872-24.182 18.602 10.519 18.046-11.878 23.483 18.928 32.697-12.905 22.76-28.881 23.216-14.056 18.938 1.8733 12.426-46.484 38.314-18.348v386.39"
                />
              </defs>
              <g fill="none" fillRule="evenodd">
                <g transform="translate(186 1)">
                  <path
                    d="m400 800c220.91 0 400-179.09 400-400s-179.09-400-400-400-400 179.09-400 400 179.09 400 400 400z"
                    opacity=".15"
                    stroke="#fff"
                    strokeDasharray="3.00000011920929,3.999999910593033"
                    strokeWidth=".5"
                  />
                  <path
                    transform="translate(244 226.53) rotate(235) translate(-244 -226.53)"
                    d="m247.43 254.82c0-19.504-2.376-38.453-6.8544-56.573"
                    stroke="url(#x-asset-page-1-integrated-e)"
                    strokeLinecap="round"
                    strokeWidth="3"
                  />
                  <path
                    d="m400 710c171.21 0 310-138.79 310-310s-138.79-310-310-310-310 138.79-310 310 138.79 310 310 310z"
                    opacity=".25"
                    stroke="#fff"
                    strokeDasharray="3.00000011920929,3.999999910593033"
                    strokeWidth=".5"
                  />
                  <path
                    d="m400 530c71.797 0 130-58.203 130-130s-58.203-130-130-130-130 58.203-130 130 58.203 130 130 130z"
                    opacity=".5"
                    stroke="#fff"
                    strokeDasharray="3.00000011920929,3.999999910593033"
                    strokeWidth=".5"
                  />
                  <path
                    d="m400 635c129.79 0 235-105.21 235-235s-105.21-235-235-235-235 105.21-235 235 105.21 235 235 235z"
                    opacity=".35"
                    stroke="#fff"
                    strokeDasharray="3.00000011920929,3.999999910593033"
                    strokeWidth=".5"
                  />
                  <path
                    transform="translate(629.83 197.97) rotate(-5) translate(-629.83 -197.97)"
                    d="m659.36 238.08c-15.302-29.879-35.313-56.948-59.052-80.228"
                    stroke="url(#x-asset-page-1-integrated-d)"
                    strokeLinecap="round"
                    strokeWidth="3"
                  />
                </g>
              </g>
            </svg>

            <svg
              version="1.1"
              className="x-asset-page-1-integrated__chrome2 rellax"
              data-rellax-speed="-2"
              role="presentation"
              viewBox="0 0 1172 1012"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(1 625)">
                  <g opacity=".15">
                    <use
                      fill="url(#x-asset-page-1-integrated-b)"
                      href="#x-asset-page-1-integrated-a"
                    />
                    <use
                      fill="url(#x-asset-page-1-integrated-c)"
                      href="#x-asset-page-1-integrated-a"
                    />
                  </g>
                  <path
                    d="m0 282.16l40.991-1.1153 22.215-3.6651 25.2 3.6651 20.578-7.2583h31.041l6.2198 7.2583 19.797-7.2583 21.136 7.2583 10.134-10.993c5.3512-0.61984 9.3204-1.2024 11.908-1.7478 3.881-0.81804 27.454-15.547 28.554-15.398 0.73379 0.099312 6.9871-1.5038 18.76-4.8093h9.3285c5.3673 4.9639 8.5879 7.4762 9.6619 7.537 1.074 0.060785 8.2134-2.4515 21.418-7.537l37.665 25.689 8.3321-11.625h10.044l20.475-22.372 11.896 2.1421 27.129-9.7484 8.9926 3.4031c5.3961-3.285 8.6528-4.4194 9.77-3.4031 1.1172 1.0162 5.0415 2.7503 11.773 5.2023l37.804-31.368 28.533-1.5218 10.886-17.776 39.142-14.989 20.057-91.908 19.264 32.66h10.456l8.8335-30.09 12.178-8.9188 7.1615 12.477h12.353l18.294-50.266 19.37 5.9767 30.838 63.28 7.7277 27.817 12.068-11.926 8.2431 9.9731 11.294 1.9533 8.7571-33.208 17.822 21.282 11.562 1.7915 8.9238 29.606c6.7483-14.134 10.383-20.624 10.903-19.471s4.1406 3.7597 10.862 7.8195l10.993-25.988 17.817-5.4271c5.4564-18.659 8.5493-28.439 9.2789-29.34s4.1864-3.0179 10.371-6.3491l28.642 49.15 10.43-8.034 9.6733 25.988 22.089-14.557 15.872-24.182 18.602 10.519 18.046-11.878 23.483 18.928 32.697-12.905 22.76-28.881 23.216-14.056 18.938 1.8733 12.426-46.484 38.314-18.348"
                    stroke="url(#x-asset-page-1-integrated-b)"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>

            {/* Send and Receive Section */}
            <div className="x__width">
              <div className="row">
                <div className="col-12 col-xl-9">
                  <img
                    className="x-asset-page-1-integrated__img"
                    src={data.img.sendReceive}
                    srcSet={`${data.img.sendReceive} 1740w, ${data.img.sendReceiveS} 640w`}
                    sizes="(min-width: 1200px) 870px, (max-width: 1199px) 100vw"
                    alt={data.img.sendReceiveAlt || `Send and Receive ${data.name}`}
                  />
                </div>

                <div className="x-asset-page-1-integrated__txt col-12 col-xl-3">
                  <Heading
                    type="h2"
                    page={page}
                    src="Assets"
                    section="sendReceive"
                    className="x-h2 x-h2--light"
                  />
                  <p
                    className="x-p x-p--light x-style-child-links"
                    dangerouslySetInnerHTML={{ __html: sendReceiveText }}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* Exchange Section */}
        <section className="x-asset-page-1-exchange">
          <div
            className="x-asset-page-1-exchange__desc"
            style={{ background: data.colors.bgExchange }}
          >
            <div className="x-asset-page-1-exchange__desc-container">
              <img
                src={`/img/logos/${data.symbol}.svg`}
                alt={`${data.name} fully integrated to Exodus wallet`}
                className="x-asset-page-1-exchange__logo"
              />

              <Heading
                type="h2"
                page={page}
                src="Assets"
                section="exchange"
                className="x-h2 x-h2--light"
              />

              <p
                className="x-p x-p--light x-style-child-links"
                dangerouslySetInnerHTML={{ __html: exchangeText }}
              />
            </div>

            <div className="x-asset-page-1-exchange__desc-chrome">
              <span
                className="x-asset-page-1-exchange__desc-chrome--before"
                style={{
                  background: data.colors.bgExchangeStripe1,
                }}
              />
              <span
                className="x-asset-page-1-exchange__desc-chrome--after"
                style={{
                  background: data.colors.bgExchangeStripe2,
                }}
              />
            </div>
          </div>
          <div className="x-asset-page-1-exchange__img" role="presentation">
            <img
              src={data.img.exchange}
              srcSet={`${data.img.exchange} 1916w, ${data.img.exchangeS} 639w`}
              sizes="(max-width: 400px) 100vw, 930px"
              alt={
                data.img.exchangeAlt ||
                `Use the Exodus ${data.symbol} wallet to swap ${data.symbol} for other cryptos and vice versa`
              }
            />
          </div>
        </section>

        {/* Assets Section */}
        <section className="x-asset-page-1-exchange-assets">
          <Heading
            type="h2"
            page={page}
            src="Assets"
            section="assets"
            className="x-h2 x-h2--light"
          />

          <div className="x-asset-page-1-exchange-assets__img">
            <img
              className="x-asset-page-1-exchange-assets__img--desktop"
              src={data.img.exchangeGraphic}
            />
            <img
              className="x-asset-page-1-exchange-assets__img--mobile"
              src={data.img.exchangeGraphicMobile}
            />
          </div>
        </section>

        {data.video && (
          <section className="x__width x-asset-page-1-video">
            <span className="x-asset-page-1-video__subheading">From Exodus Video Blog</span>
            <div className="x-asset-page-1-video__container">
              <iframe
                src={`https://www.youtube.com/embed/${data.video}`}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </div>
          </section>
        )}

        {/* Trezor Section */}
        {assetData?.trezor && data.img.trezor ? (
          <div className="x-asset-page-1-send-receive">
            <div className="x__width">
              <div className="row">
                <div className="col-12 col-xl-9">
                  <img
                    className="x-asset-page-1-integrated__img"
                    src={data.img.trezor}
                    srcSet={`${data.img.trezor} 1740w, ${data.img.trezorS} 640w`}
                    sizes="(min-width: 1200px) 870px, (max-width: 1199px) 100vw"
                    alt={`Exodus ${data.name} wallet integrated with Trezor hardware wallet`}
                  />
                </div>

                <div className="x-asset-page-1-integrated__txt col-12 col-xl-3">
                  <img
                    src={`/img/logos/${data.symbol}.svg`}
                    alt={`Send and Receive ${data.name}`}
                    className="x-asset-page-1-integrated__logo"
                  />

                  <Heading
                    type="h2"
                    page={page}
                    src="Assets"
                    section="trezor"
                    className="x-h2 x-h2--light"
                  />
                  <p
                    className="x-p x-p--light x-style-child-links"
                    dangerouslySetInnerHTML={{ __html: trezorText }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="x-asset-page-1-send-receive">
            {/* Portfolio Section */}
            <div className="x__width">
              <div className="row">
                <div className="col-12 col-xl-9">
                  <img
                    className="x-asset-page-1-integrated__img"
                    src={data.img.portfolio}
                    srcSet={`${data.img.portfolio} 1740w, ${data.img.portfolioS} 640w`}
                    sizes="(min-width: 1200px) 870px, (max-width: 1199px) 100vw"
                    alt={`Exodus ${data.name} wallet with portfolio data and charts.`}
                  />
                </div>
                <div className="x-asset-page-1-integrated__txt col-12 col-xl-3">
                  <img
                    src={`/img/logos/${data.symbol}.svg`}
                    alt={`Send and Receive ${data.name}`}
                    className="x-asset-page-1-integrated__logo"
                  />
                  <Heading
                    type="h2"
                    page={page}
                    src="Assets"
                    section="portfolio"
                    className="x-h2 x-h2--light"
                  />
                  <p
                    className="x-p x-p--light x-style-child-links"
                    dangerouslySetInnerHTML={{ __html: portfolioText }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Skin Section */}
        {data.skin && (
          <section className="x-asset-page-1-skin">
            <div
              className="x-asset-page-1-skin__desc"
              style={{ background: data.colors.bgExchange }}
            >
              <div className="x-asset-page-1-skin__desc-container">
                <img
                  src={`/img/logos/${data.symbol}.svg`}
                  alt={`${data.name} fully integrated to Exodus wallet`}
                  className="x-asset-page-1-skin__logo"
                />

                <Heading
                  type="h2"
                  page={page}
                  src="Assets"
                  section="skin"
                  className="x-h2 x-h2--light"
                />

                <p
                  className="x-p x-p--light x-style-child-links"
                  dangerouslySetInnerHTML={{ __html: data.skin.skinText }}
                />
              </div>

              <div className="x-asset-page-1-skin__desc-chrome">
                <span
                  className="x-asset-page-1-skin__desc-chrome--before"
                  style={{
                    background: data.colors.bgExchangeStripe1,
                  }}
                />
                <span
                  className="x-asset-page-1-skin__desc-chrome--after"
                  style={{
                    background: data.colors.bgExchangeStripe2,
                  }}
                />
              </div>
            </div>
            <div className="x-asset-page-1-skin__img" role="presentation">
              <img
                src={data.skin.skinImg}
                srcSet={`${data.skin.skinImg} 1916w, ${data.skin.skinImgS} 639w`}
                sizes="(max-width: 400px) 100vw, 930px"
                alt={data.skin.skinTitle || ''}
              />
            </div>
          </section>
        )}

        {/* Mobile Apps Section */}
        {assetData.mobile && data.img.mobile1 && (
          <div className="x-asset-page-1-mobile">
            <div className="x__width">
              <Heading
                type="h2"
                page={page}
                src="Assets"
                section="mobileApps"
                className="x-h2 x-h2--light"
              />
              <p
                className="x-p x-p--light x-style-child-links"
                dangerouslySetInnerHTML={{ __html: mobileText }}
              />
              <MobileDownloadButtons altBackgroundGradient />
            </div>
            <ResponsiveCarousel
              className="x-asset-page-1-mobile__images x__width"
              outerClassName=""
              id="assetPageCarousel"
              innerClassName="row"
              responsiveCutoff={768}
              dark
            >
              <div className="x-asset-page-1-mobile__images-container">
                <div className="x-asset-page-1-mobile__images-img">
                  <img
                    src={data.img.mobile1}
                    alt={data.img.mobileAlt || `Experience ${data.name} on Exodus Mobile`}
                  />
                </div>
              </div>

              <div className="x-asset-page-1-mobile__images-container active">
                <div className="x-asset-page-1-mobile__images-img">
                  <img
                    src={data.img.mobile2}
                    alt={data.img.mobileAlt || `Experience ${data.name} on Exodus Mobile`}
                  />
                </div>
              </div>

              <div className="x-asset-page-1-mobile__images-container">
                <div className="x-asset-page-1-mobile__images-img">
                  <img
                    src={data.img.mobile3}
                    alt={data.img.mobileAlt || `Experience ${data.name} on Exodus Mobile`}
                  />
                </div>
              </div>
            </ResponsiveCarousel>
          </div>
        )}

        {/* Chart Section */}
        <section className="x-assets-page-1-markets x__width">
          <Heading
            type="h2"
            page={page}
            src="Assets"
            section="chart"
            className="x-h2 x-h2--light"
          />

          <iframe id="graph-iframe" src={`/chart?asset=${data.symbol}`} />
        </section>

        {/* About Section */}
        <section className="x-asset-page-1-about x__width">
          <div className="x-asset-page-1-about__info">
            <Heading
              type="h2"
              page={page}
              src="Assets"
              section="about"
              className="x-h2 x-h2--light"
            />
            {Array.isArray(data.desc) ? (
              data.desc.map((item, index) => (
                <p key={index} className="x-p x-p--light">
                  {item}
                </p>
              ))
            ) : (
              <p className="x-p x-p--light">{data.desc}</p>
            )}
            <div className="x-asset-page-1-about__tags">
              {data.descTags.map((tag, index) => {
                return <span key={index}>{tag}</span>
              })}
            </div>

            <h3 className="x-asset-page-1-about__heading">Links</h3>
            <div className="x-asset-page-1-about__links">
              {data.links.map((item, index) => {
                const firstInSet = !!((index + 1) % 2)
                if (!firstInSet) return null
                const next = data.links[index + 1]
                return (
                  <div key={index}>
                    <LinkItem url={item.url} type={item.type} />
                    {next && <LinkItem url={next.url} type={next.type} />}
                  </div>
                )
              })}
            </div>
          </div>
        </section>

        {/* Purchase Section */}
        {data.purchase && (
          <section className="x-asset-page-1-purchase x__width">
            <div className="x-asset-page-1-purchase__info">
              <Heading
                type="h2"
                page={page}
                src="Assets"
                section="purchase"
                className="x-h2 x-h2--light"
              />
              <p className="x-p x-p--light">{data.purchase}</p>
            </div>
          </section>
        )}

        {/* Download Section */}
        <section className="x-asset-page-1-get-exodus x__width">
          <svg
            version="1.1"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            role="presentation"
          >
            <g fill="none" fillRule="evenodd" opacity=".2">
              <path
                d="M19.8802 5.5843L11.3633 0V3.12221L16.827 6.67261L16.1842 8.70651H11.3633V11.2935H16.1842L16.827 13.3274L11.3633 16.8778V20L19.8802 14.4335L18.4875 10.0089L19.8802 5.5843Z"
                fill="#FFF"
              />
              <path
                d="M3.95338 11.2935H8.75643V8.70651H3.93553L3.3106 6.67261L8.75643 3.12221V0L0.239502 5.5843L1.63221 10.0089L0.239502 14.4335L8.77429 20V16.8778L3.3106 13.3274L3.95338 11.2935Z"
                fill="#FFF"
              />
            </g>
          </svg>

          <Heading
            type="h2"
            page={page}
            src="Assets"
            section="download"
            className="x-h2 x-h2--light"
          />

          <Link
            to="/download/"
            className="x__button"
            style={{
              backgroundImage: `linear-gradient(-62deg, ${data.colors.stop} 4%, ${data.colors.start} 100%)`,
            }}
          >
            <span className="x__button-align">
              <span className="x__button-align-text">
                {data.footerDownloadButton || 'Download Exodus'}
              </span>
            </span>
          </Link>
        </section>

        {/* Why Exodus Section */}
        <section className="x-asset-page-1-why-use x__width">
          <Heading type="h2" page={page} src="Assets" section="whyExodus" className={null} />

          <div className="row">
            <div className="col-12 col-md-4">
              <div className="x-asset-page-1-why-use__img">
                <img src={data.img.iconShield} alt="Private and Secure" />
              </div>
              <h3>{privateHeader}</h3>
              <p
                className="x-p x-p--light text-center x-style-child-links"
                dangerouslySetInnerHTML={{ __html: privateText }}
              />
            </div>
            <div className="col-12 col-md-4">
              <div className="x-asset-page-1-why-use__img">
                <img src={data.img.iconUser} alt="User-friendly" />
              </div>
              <h3>{userFriendlyHeader}</h3>
              <p
                className="x-p x-p--light text-center x-style-child-links"
                dangerouslySetInnerHTML={{ __html: userFriendlyText }}
              />
            </div>
            <div className="col-12 col-md-4">
              <div className="x-asset-page-1-why-use__img">
                <img src={data.img.iconAssets} alt="Built-in Exchange" />
              </div>
              <h3>{exchangePromoHeader}</h3>
              <p
                className="x-p x-p--light text-center x-style-child-links"
                dangerouslySetInnerHTML={{ __html: exchangePromoText }}
              />
            </div>
          </div>
        </section>

        {/* Assets Carousel Section */}
        <section className="x-carousel-assets">
          <h3>
            <span className="x-carousel-assets__number">
              <AnimatedNumber
                id="assets-supported"
                role="presentation"
                value={assetsCount}
                duration={1000}
              />
              <span>+</span>
            </span>
            {assetsCount}+ assets supported
          </h3>

          <LogosCarousel dark />
          <Link
            to={ASSETS_PAGE_PATH}
            role="button"
            className="x__button x__button--secondary x__button--top-margin"
          >
            <span className="x__button-align">
              <span className="x__button-align-text">See the Full List</span>
            </span>
          </Link>
        </section>
        <Footer />
      </main>
    </div>
  )
}
export default AssetPage1

// <head> component:
export function Head({ pageContext }) {
  const page = pageContext?.data?.symbol?.toLowerCase() || 'status'
  return <PageHead page={page} src="Assets" path={pageContext.data.path} />
}
