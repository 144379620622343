import makeRequest from 'src/js/utils/make-request'
import { isDev, PRICE_FEED_API_URL } from 'src/constants'

export const ASSETS_WITH_HIDDEN_APY = ['LUNA']

const fetchAssestAPYFromPricingServer = async () => {
  let result = {}
  try {
    const stakingRewards = await makeRequest(
      isDev ? `${PRICE_FEED_API_URL}/staking/rewards` : '/price-feed-api/staking/rewards'
    )

    if (!stakingRewards || !stakingRewards.length) return {}

    result = stakingRewards.reduce((acc, next) => {
      if (!Number.isFinite(next.reward))
        throw new Error(`wrong apy rates data ${JSON.stringify(stakingRewards)}`)
      return {
        ...acc,
        [next.asset]: next.reward.toFixed(2),
      }
    }, {})
    result.CDAI = result.DAI
  } catch (e) {
    console.error('failed to fetch apy rates from pricing-server')
    console.error(e)
  }

  const filterAssetsWithHiddenAPY = (obj, arr) => {
    arr.forEach((symbol) => delete obj[symbol])
    return obj
  }

  return filterAssetsWithHiddenAPY(result, ASSETS_WITH_HIDDEN_APY)
}

export const fetchAssetsAPY = fetchAssestAPYFromPricingServer

export const fetchDaiAPY = async () => {
  try {
    const result = await fetchAssetsAPY()
    return result.CDAI
  } catch (e) {
    console.error(e)
  }
}

export default fetchAssetsAPY
